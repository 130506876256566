$primary-opacity: 0.85
$secondary-opacity: 0.5
$primary-light: rgba(0, 0, 0, $primary-opacity)
$secondary-light: rgba(0, 0, 0, $secondary-opacity)
$primary-dark: rgba(255, 255, 255, $primary-opacity)
$secondary-dark: rgba(255, 255, 255, $secondary-opacity)

$background-light: white
$background-dark: #222
$background-navy: #003
$background-antique: antiquewhite

$transition-duration: 200ms
