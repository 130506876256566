@use 'sass:color'
@use 'colors'

$faded-light: color.scale(colors.$primary-light, $alpha: -75%)
$faded-dark: color.scale(colors.$primary-dark, $alpha: -75%)

body.edit
	display: grid
	grid-template-columns: repeat(2, 50vw)
	grid-template-rows: min-content auto

.nav
	grid-row: 1
	border-bottom: 1px solid

#settings.nav
	position: initial
	font-size: 100%
	color: inherit
	*
		margin: .5em
		padding: .5em
		background-color: $faded-light
		cursor: pointer

.theme-kind-dark #settings.nav *
	background-color: $faded-dark

.theme-kind-light
	.nav
		border-color: $faded-light

.theme-kind-dark
	.nav
		border-color: $faded-dark

.nav-left
	grid-column: 1

.nav-right
	grid-column: 2
	border-left: 1px solid

#writepad
	grid-column: 1
	grid-row: 2
	resize: none
	overflow: auto
	padding: 2rem
	background: none
	color: inherit
	font-family: monospace, monospace
	border: none

#preview
	grid-column: 2
	grid-row: 2
	background-color: darkred
	overflow:
		x: hidden
		y: auto
	.theme-kind-light &
		background-color: $faded-light
	.theme-kind-dark &
		background-color: $faded-dark

#slides.preview
	position: static
	font-size: 100%
	width: 50vw
	height: 50vh

	.slide:first-child
		margin-top: 25vh

	.slide:last-child
		margin-bottom: 25vh

	.slide
		transform: scale(0.95)
		transition: transform colors.$transition-duration
		box-shadow: 0 0 1rem rgba(0,0,0,0.5)
		cursor: pointer

	.slide-focus
		transform: scale(0.97)

.theme-navy
	.slide
		background-color: colors.$background-navy

.theme-antique
	.slide
		background-color: colors.$background-antique

.theme-light
	.slide
		background-color: colors.$background-light

.theme-dark
	.slide
		background-color: colors.$background-dark

#nav-title
	font-size: 100%
	text-align: center
	text-overflow: ellipsis
	overflow: hidden
	white-space: nowrap
	margin: 1em
