@use 'colors'

// General styling

body
	padding: 0
	margin: 0
	width: 100vw
	height: 100vh
	overflow: hidden
	font-size: 1rem
	position: absolute
	font-family: sans-serif

button, input
	color: inherit
	font-size: inherit
	background: none

h1,h2,h3,h4,h5,h6
	font-family: serif

h1
	font-size: 180%

h2
	font-size: 160%

h3
	font-size: 140%

a
	color: rgba(0, 0, 255, colors.$primary-opacity)
	&:visited, &:active
		color: rgba(255, 0, 255, colors.$primary-opacity)

ul, ol, dl, table, pre
	text-align: left

table
	border-collapse: collapse

tbody
	border:
		top: 1px solid
		bottom: 1px solid

th, td
	padding: .2ex 1ex

// Welcome form

#welcome
	margin: 10vh auto
	max-width: 40vw
	padding: 2rem

	*:first-of-type
		margin-top: 0

#welcome-url-input
	flex-grow: 1

.form-row
	display: flex
	flex-direction: row

	> *
		padding: .4rem .8rem
		background-color: none
		border: 1px solid
		border:
			left: none
			color: colors.$secondary-light

		&:first-child
			border:
				left: 1px solid
				top-left-radius: .4rem
				bottom-left-radius: .4rem

		&:last-child
			border:
				top-right-radius: .4rem
				bottom-right-radius: .4rem

// Slides

#slides
	position: absolute
	transition: top colors.$transition-duration
	font-size: 200%
	width: 100vw
	height: 100vh

.slide
	width: 100%
	height: 100%
	box-sizing: border-box
	padding: 6vh 6vw
	display: flex
	flex-direction: column
	justify-content: center
	overflow: hidden

	&.notext
		align-items: center
		text-align: center

// Controls

#controls,
#settings
	position: absolute
	bottom: 0
	right: 0
	font-size: 1.5rem
	color: colors.$secondary-light

	> *
		border: none
		margin: .5rem

#settings
	right: unset
	left: 0

// Basic themes

.theme-kind-light
	background-color: colors.$background-light
	color: colors.$primary-light

.theme-kind-dark
	background-color: colors.$background-dark
	color: colors.$primary-dark

	.form-row > *
		border-color: colors.$secondary-dark

	#controls, #settings
		color: colors.$secondary-dark

	a
		color: rgba(196, 196, 255, colors.$primary-opacity)
		&:visited, &:active
			color: rgba(255, 196, 255, colors.$primary-opacity)

.theme-navy
	background-color: colors.$background-navy

.theme-antique
	background-color: colors.$background-antique
